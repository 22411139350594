// import 'react-app-polyfill/ie9'; // For IE 9-11 support
// import 'react-app-polyfill/stable';
// import 'react-app-polyfill/ie11'; // For IE 11 support
// import './polyfill'
// import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/vendors/font-awesome/css/font-awesome.min.css";
import "./assets/css/style.css";
import "./assets/vendors/ap8/css/style.css";
import "jquery/dist/jquery.slim.min.js";
import "popper.js/dist/popper.min.js";
import {BrowserRouter} from 'react-router-dom';
import serviceWorkerRegister from './serviceWorker';

var $ = require('jquery');
global.jQuery = require('jquery')
window.$ = $;
require('bootstrap');

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorkerRegister();
